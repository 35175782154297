// スクロールするとヘッダー変形
$(window).scroll(function () {
  const header = $('.l-header');
  const headerHeight = document.querySelector('.l-header').offsetHeight;

  if ($(this).scrollTop() > headerHeight) {
    header.addClass('l-header--scroll');
  }else {
    header.removeClass('l-header--scroll');
  }
});